export const WORDS = [
  "STROM",
  "DÁREK",
  "SÁŇKY",
  "JMELÍ",
  "KAPŘI",
  "SVÍCE",
  "VEČER",
  "KOMÍN",
  "SANTA",
  "KLAUS",
  "JEŽÍŠ",
  "DÁRKY",
  "SALÁT",
  "ZVYKY",
  "PŘÁNÍ",
  "VĚNEC",
  "KOULE",
  "JEDLE",
  "BAŇKA",
  "MAŠLE",
  "PAPÍR",
  "ŘÍZEK",
  "KOKOS",
  "STUHA",
  "ADVEN",
  "KABÁT",
  "SVETR",
  "ANDĚL",
  "OLOVO",
  "BUNDA",

  // "AGENT",
  // "RYBKA",
  // "ŽALUD",
  // "KONEC",
  // "STROM",
  // "KNIHA",
  // "PTÁCI",
  // "KLOUB",
  // "PLÁŠŤ",
  // "HRNEK",
  // "MĚSTO",
  // "OBRAZ",
  // "SMÍCH",
  // "BARVA",
  // "ZVÍŘE",
  // "TRÁVA",
  // "KÁMEN",
  // "OVOCE",
  // "PÍSEK",
  // "KOLEK",
  // "PLÁŽE",
  // "ŽEBRA",
  // "CHLÉB",
  // "VĚTEV",
  // "OBLEK",
  // "SLÁVA",
  // "ZÁMEK",
  // "PLZEŇ",
  // "KOMÍN",
  // "KOSTI",
  // "VÍDEŇ",
  // "HRNCE",
  // "STĚNA",
  // "KOULE",
  // "ROBOT",
  // "KNIHY",
  // "VOZÍK",
  // "LÁSKA",
  // "SLOVA",
  // "LISTY",
];
